<template>
	<div class="main-content">
		<page-header title="Marshal Management" pre-title="Overview" :loading="false">
		</page-header>
		<template>
			<b-breadcrumb class="p-4 m-1" :items="items"></b-breadcrumb>
		</template>
		<div class="container-fluid">
			<div class="row">
				<div class="col-12">
					<div class="card">
						<div class="card-header justify-content-between">
							<div class="input-group input-group-flush d-flex flex-row-reverse">
								<input v-model.trim="search" class="form-control list-search" type="search" placeholder="Search" />
								<span class="input-group-text border-0">
									<i class="fe fe-search"></i>
								</span>
							</div>
							<div class="col-auto" v-if="marshals.length">
								<v-datepicker style="width: 100%" v-model="filter.range" placeholder="Today" range></v-datepicker>
							</div>
							<div class="">
								<download-button v-if="marshals.length" :downloading="downloadingReport" label="Download Report"
									@download="downloadReport()"></download-button>
							</div>
						</div>
						<div class="col px-0 d-flex">
							<div class="col"></div>
							<div class="col-auto">
								<filter-button :options="statusBar" :loading="false">
									<template #label>
										<span class="text-grey">Status:</span> <span class="text-capitalize">{{ statusFilter }}</span>
									</template>

									<template #option="data">
										<span class="w-100 d-inline" @click="selectSortOrder(data.value)">
											{{ data.value }}
										</span>
									</template>
								</filter-button>
							</div>
						</div>
						<b-table striped hover selectable responsive :items="marshals" :fields="fields" :current-page="currentPage"
							:busy="fetchingMarshals" @row-clicked="viewMarshals">
							<template #cell(user)="data">
								<p class="font-weight-bold" style="line-height: 1">
									<router-link class="sh-page-link" :to="{
										name: 'ShowMarshal',
										params: { marshalId: data.item.id }
									}">{{ `${data.item.fname} ${data.item.lname}` }}</router-link>
								</p>
								<p style="line-height: 1">
									<router-link class="sh-page-link" :to="{
										name: 'ShowMarshal',
										params: { marshalId: data.item.id }
									}">{{ data.item.email }}</router-link>
								</p>
							</template>
							<template #cell(phone_number)="data">
								<p style="line-height: 1">
									<router-link class="sh-page-link" :to="{
										name: 'ShowMarshal',
										params: { marshalId: data.item.id }
									}">{{ data.item.phone }}</router-link>
								</p>
							</template>
							<template #cell(routes_assigned)="data">
								<span v-for="(route, index) in data.item.routes_assigned" :key="index">
									<small class="route-code p-2 font-weight-bolder rounded-pill mx-2">{{ route }}</small>
								</span>
							</template>
							<template #cell(total_checkin)="data">
								<span>{{ data.item.total_checkin }}</span>
							</template>
							<template #cell(compliance)="data">
								<span>{{ data.item.compliance }}</span>
							</template>
							<template #cell(amount_payable)="data">
								<span>{{ data.item.amount_payable }}</span>
							</template>
						</b-table>

						<div class="card-footer" v-if="totalRecords">
							<b-pagination v-model="currentPage" :total-rows="totalRecords" :per-page="perPage"></b-pagination>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import PageHeader from '@/components/layout/PageHeader'
// import AverageRating from '@/components/core/AverageRating.vue';
import { ExportToCsv } from 'export-to-csv'
import moment from 'moment'
import { joinWithRatingsData } from '@/utils/helpers'
import DownloadButton from '@/components/core/DownloadButton.vue'
import FilterButton from '@/components/core/FilterButton'
import _ from 'lodash'

export default {
  components: {
    // AverageRating,
    DownloadButton,
    PageHeader,
    FilterButton
  },
  data() {
    return {
      fields: [
        {
          key: 'user',
          label: 'User'
        },
        {
          key: 'phone',
          label: 'Phone Number'
        },
        {
          key: 'routes_assigned',
          label: 'Routes Assigned'
        },
        {
          key: 'total_checkin',
          label: 'Total Check-In'
        },
        {
          key: 'compliance',
          label: 'Compliance'
        },
        {
          key: 'amount_payable',
          label: 'Amount Payable'
        }
      ],
      items: [
        {
          text: 'Marshal Management',
          href: '#'
        },
        {
          text: 'Marshal Summary',
          href: '#'
        }
      ],
      perPage: 20,
      currentPage: 1,
      totalRecords: 0,
      marshals: [],
      fetchingMarshals: false,
      search: '',
      processing: false,
      statusFilter: 'active',
      downloadingReport: false,
      filter: {
        range: []
      },
      statusBar: ['Active', 'Inactive', 'Suspend']
    }
  },
  created() {
    this.initMarshals()
    this.debouncedCallback = _.debounce(() => {
      this.initMarshals(true)
    }, 1000)
  },
  computed: {
    filteredMarshals() {
      const search = this.search.toLowerCase()

      const marshals = this.marshals.filter((marshal) => {
        return (
          marshal.fname.toLowerCase().includes(search) ||
          marshal.lname.toLowerCase().includes(search) ||
          marshal.email.toLowerCase().includes(search) ||
          marshal.phone_number.toLowerCase().includes(search)
        )
      })

      return marshals
    },

    dateRange() {
      return this.filter.range.map((date) => moment(date).format('YYYY-MM-DD'))
    },

    dateFilterValid() {
      return (
        this.filter.range.length &&
        this.filter.range.filter((date) => !!date).length > 0
      )
    }
  },
  watch: {
    currentPage() {
      this.initMarshals()
    },
    search() {
      this.debouncedCallback()
    },
    statusFilter() {
      this.initMarshals(true)
    },
    dateFilterValid() {
      this.initMarshals(true)
    }
  },
  methods: {
    async fetchTripRatingSettings(serviceId) {
      const tripRatingSettingsResponse = await this.axios.get(
        `/rating/settings/service-id/${serviceId}`
      )
      return tripRatingSettingsResponse.data.data
    },
    async initMarshals(reset = false) {
      if (reset) {
        this.currentPage = 1
      }

      this.fetchingMarshals = true

      const { reference } = await this.fetchTripRatingSettings(
        process.env.VUE_APP_TRIP_RATING_SERVICE_ID
      )
      this.settingsId = reference

      const ratingsPayload = {
        settings_id: this.settingsId,
        parameter: 'marshal_id',
        start_date: '2022-01-01',
        end_date: `${moment(new Date()).format('YYYY-MM-DD')}`
      }
      Promise.all([
        Array.isArray(this.dateRange) && !this.dateRange.length
          ? this.axios.get(`/v1/bus-marshals?page=${this.currentPage}&limit=${this.perPage}&search=${this.search}&metadata=true&status=${this.statusFilter}`)
          : this.axios.get(`/v1/bus-marshals?metadata=true&page==${this.currentPage}&limit=${this.perPage}&start_date_filter=${this.dateRange[0]}&end_date_filter=${this.dateRange[1]}`),
        this.axios.post('/rating/reports/generic', ratingsPayload)
      ])
        .then((res) => {
          const marshals = res[0].data.data
          const marshalsRatings = res[1].data.data
          const mergedData = joinWithRatingsData(marshals, marshalsRatings)
          this.marshals = mergedData
          this.totalRecords = res[0].data?.metadata?.total
        })
        .catch(() => { })
        .finally(() => (this.fetchingMarshals = false))
    },
    selectMarshal(selectedMarshal) {
      const index = this.selectedMarshals.findIndex(
        (marshal) => marshal.id === selectedMarshal.id
      )

      if (index !== -1) {
        this.selectedMarshals.splice(index, 1)
      } else {
        this.selectedMarshals.push(selectedMarshal)
      }
    },
    async deactivateSelectedMarshals() {
      const result = await this.$swal({
        icon: 'question',
        title: 'Please Confirm',
        text: 'Are you sure you want to deactivate selected marshal(s)?',
        showConfirmButton: true,
        showCancelButton: true
      })
      if (result.isConfirmed) {
        this.processing = true

        const payload = {
          ids: this.selectedMarshals.map((marshal) => marshal.id),
          active: 0
        }

        this.axios
          .patch('/v1/bus-marshals/update-availability', payload)
          .then(() => {
            this.$swal({
              icon: 'success',
              title: 'Marshals deactivated',
              text: 'All selected Marshals were successfully deactivated',
              showCloseButton: true
            })
          })
          .finally(() => {
            this.reset()
            this.initMarshals()
          })
      }
    },
    reset() {
      this.processing = false
      this.selectedMarshals = []
      this.checkAllMarshals = false
    },
    viewMarshal(marshal) {
      this.$router.push({ name: 'ShowMarshal', params: { marshalId: marshal.id } })
    },
    downloadReport() {
      this.downloadingReport = true
      const csvData = this.filteredMarshals.map((data) => {
        return {
          name: data.fname + ' ' + data.lname,
          average_rating: data.average_rating,
          date_joined: data.created_at,
          status: data.active == 1 ? 'Active' : 'Inactive'
        }
      })

      const csvParams = {
        fieldSeparator: ',',
        filename: !this.dateRange.length ? 'Marshal List' : `Marshal List from ${this.dateRange[0]}  to ${this.dateRange[1]}`,
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: true,
        title: !this.dateRange.length ? 'Marshal List' : `Marshal List from ${this.dateRange[0]}  to ${this.dateRange[1]}`,
        useTextFile: false,
        useBom: true,
        headers: [
          'Name',
          'Average Rating',
          'Date Joined',
          'Status'
        ]
      }

      const csvExporter = new ExportToCsv(csvParams)

      csvExporter.generateCsv(csvData)
      this.downloadingReport = false

      this.$swal({
        icon: 'success',
        title: 'Report downloaded',
        text: 'Report has been downloaded successfully',
        showCloseButton: true
      })
    },
    viewMarshals(marshal) {
      this.$router.push({ name: 'ShowMarshal', params: { marshalId: marshal.id } })
    }
  },
  beforeDestroy() {
    this.debouncedCallback.cancel()
  }
}
</script>
<style scoped>
.route-code {
  background: #D3DCE6;
  color: black;
  font-size: 12px;
}
</style>
